import React, {
  useEffect,
  useState,
} from 'react';

import AddAddress from 'components/Address/addAddress';
import ChangePasswordDialog from 'components/Auth/ChangePasswordDialog';
import LoadingIndicator from 'components/LoadingIndicator';
import {
  IAddress,
  IAddressPatch,
  IAddressPost,
  IUserPatch,
  IWorkingHours,
} from 'interfaces';
import { useSnackbar } from 'notistack';
import {
  useMutation,
  useQuery,
} from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  changePassword,
  connectAccount,
  deleteUserAddress,
  getUserAddresses,
  patchUserAddress,
  postUserAddress,
  putUserMe,
} from 'service';

import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StarIcon from '@mui/icons-material/Star';
import {
  Divider,
  Grid,
  ListItem,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import WorkingHours from '../components/WorkingHours';
//import EmailConfirmationStatus from "components/EmailConfirmationStatus";
import {
  updateUserInStorage,
  useUser,
} from '../hooks/useUser';
import {
  getCurrentUser,
  isHolder,
  isLoggedIn,
  verifyAccount,
} from '../utils';

const AddressListItem: React.FC<{
  index: number;
  address: IAddress;
  onDelete: any;
  onMakePrimary: any;
}> = ({ index, address, onDelete, onMakePrimary }) => {
  const {
    id,
    name,
    street,
    city,
    province,
    country,
    postal,
    isDefault,
    operationHours,
    locationNote,
    formattedAddress,
  } = address;

  return (
    <div>
      <Typography variant="body1">
        {/*1251 Jervis Street, Vancouver, BC, Canada*/}
        {`${index}. ${formattedAddress}`}
        {!isDefault && (
          <>
            <Tooltip title="Make address primary">
              <IconButton onClick={() => onMakePrimary(id)}>
                <StarIcon />
              </IconButton>
            </Tooltip>
            <IconButton onClick={() => onDelete(id)}>
              <DeleteIcon />
            </IconButton>
          </>
        )}
        {isDefault && (
          <Tooltip title="Primary address">
            <StarIcon />
          </Tooltip>
        )}
      </Typography>
      {/*{address_2 && <Typography variant="body1">{address_2}</Typography>}*/}
    </div>
  );
};

const AddressList: React.FC<{
  addresses: IAddress[];
  onAddressUpdate: any;
}> = ({ addresses, onAddressUpdate }) => {
  const { refetch } = useUser();
  const patchUserAddressMutation = useMutation<Response, Error, IAddressPatch>(
    patchUserAddress,
    {
      onSuccess: (_data, variables) => {
        onAddressUpdate(variables);
      },
      onError: (error) => {},
    }
  );

  const deleteUserAddressMutation = useMutation<Response, Error>(
    deleteUserAddress,
    {
      onSuccess: (_data, variables) => {
        //window.location.reload();
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      },
      onError: (error) => {},
    }
  );

  const onMakePrimary = (id: string) => {
    console.log("onMakePrimary id", id);

    patchUserAddressMutation.mutateAsync({
      addressId: id,
      is_primary: true,
    });
  };

  const onDelete = (id: string) => {
    console.log(id);
    deleteUserAddressMutation.mutateAsync(id);
  };

  return (
    <List>
      {addresses.map((address, index) => (
        <div key={address.id}>
          <ListItem>
            <AddressListItem
              index={index + 1}
              address={address}
              onDelete={onDelete}
              onMakePrimary={onMakePrimary}
            />
          </ListItem>
        </div>
      ))}
    </List>
  );
};

const Account: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    data: userAddressesResults = [],
    isLoading,
    isFetching,
    error,
  } = useQuery(
    ["address"], // queryKey
    getUserAddresses.bind(this)
  );
  const { refetch } = useUser();
  const [userAddresses, setUserAddresses] = useState<IAddress[]>(
    userAddressesResults.addresses || []
  );

  if (!isLoggedIn()) {
    window.location.href = `${process.env.PUBLIC_URL}/login`;
  }

  useEffect(() => {
    if (userAddressesResults.addresses) {
      const primaryAddress = userAddressesResults.addresses.find(
        (address) => address.isDefault
      );

      console.log("primaryAddress", primaryAddress);
      setUserAddresses(userAddressesResults.addresses);
    }
  }, [userAddressesResults, refetch]);

  const postAddressMutation = useMutation<Response, Error, IAddressPost>(
    postUserAddress,
    {
      onSuccess: (_data, variables) => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },

      onError: (error: any) => {
        const errorText = error?.msg || 'Something wrong happened, please try again';
        enqueueSnackbar(errorText, { variant: "error" });
        console.error("postAddressMutation error", error);
      },
    }
  );

  const handleAddAddress = async (newAddress: IAddressPost) => {
    try {
      await postAddressMutation.mutateAsync(newAddress);
    } catch (error: any) {
      console.error("Error adding address:", error);
    }
  };

  const [addAddressOpen, setAddAddressOpen] = React.useState(false);

  const currentUser = getCurrentUser();
  const [locationNote, setLocationNote] = useState(
    currentUser?.locationNote || ""
  );

  useEffect(() => {
    updateUserInStorage({ locationNote: locationNote });
    const newAddresses = [];
    for (const address of userAddresses) {
      if (address.id === getCurrentUser().default_address_id) {
        newAddresses.push({
          ...address,
          locationNote: locationNote,
        });
      } else {
        newAddresses.push({
          ...address,
        });
      }
    }
    setUserAddresses(newAddresses);
  }, [locationNote]);

  const [isAboutNotesEditing, setIsAboutNotesEditing] = React.useState(false);
  const handleAboutNotesEditClick = () => {
    setIsAboutNotesEditing(true);
  };

  const [operationHours, setOperationHours] = useState<IWorkingHours>(
    getCurrentUser()?.operationHours || {}
  );

  useEffect(() => {
    updateUserInStorage({ operationHours: operationHours });
    const newAddresses = [];
    for (const address of userAddresses) {
      if (address.id === getCurrentUser().default_address_id) {
        newAddresses.push({
          ...address,
          operationHours: operationHours,
        });
      } else {
        newAddresses.push({
          ...address,
        });
      }
    }
    setUserAddresses(newAddresses);
  }, [operationHours]);

  const [isWorkingHoursEditing, setIsWorkingHoursEditing] =
    React.useState(false);

  const handleWorkingHoursEditClick = () => {
    setIsWorkingHoursEditing(true);
  };
  // address notes
  const putUserMeMutation = useMutation<Response, Error, IUserPatch>(
    putUserMe,
    {
      onSuccess: (_data, variables) => {
        if (variables.locationNote) {
          updateUserInStorage({ locationNote });
        }
      },

      onError: (error) => {},
    }
  );

  const handleAboutNotesSaveClick = () => {
    setIsAboutNotesEditing(false);
    putUserMeMutation.mutateAsync({
      locationNote: locationNote,
      addressId: getCurrentUser().default_address_id,
    });
  };

  const [isConnecting, setIsConnecting] = React.useState(false);

  const handleClickOpen = () => {
    refetch()
      .then(async (data) => {
        try {
          const user = getCurrentUser();
          const isAccountVerified = verifyAccount(user);
          if (isHolder() && !isAccountVerified) {
            setIsConnecting(true);
            enqueueSnackbar("A payout method is required", {
              variant: "success",
            });
            const url = await connectAccount();
            window.location.replace(url.url);
            return;
          }
          setAddAddressOpen(true);
        } catch (error: any) {
          const errorText =
            error?.msg || "Something went wrong, please try again";
          console.error(errorText);
          enqueueSnackbar(errorText, { variant: "error" });
          setIsConnecting(false);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const handleProcessPayment = async () => {
    try {
      setIsConnecting(true);
      enqueueSnackbar("Process your payment", { variant: "success" });
      const url = await connectAccount();
      window.location.replace(url.url);
    } catch (error: any) {
      const errorText = error?.msg || "Something went wrong, please try again";
      enqueueSnackbar(errorText, { variant: "error" });
      setIsConnecting(false);
    }
  };

  const daysOfWeek: string[] = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [changePasswordOpen, setChangePasswordOpen] = useState(false);

  const handleOpenChangePassword = () => {
    setChangePasswordOpen(true);
  };

  const handleCloseChangePassword = () => {
    setChangePasswordOpen(false);
  };

  const handleChangePassword = async (
    oldPassword: string,
    password: string
  ) => {
    try {
      const response = await changePassword({ oldPassword, password });
      enqueueSnackbar("Password changed successfully", { variant: "success" });
      console.log("Password changed successfully");
      console.log("Response:", response);
      navigate("/login");
    } catch (error) {
      console.error("Error changing password:", error);
    }
  };

  return (
    <div>
      {isConnecting && <LoadingIndicator />}
      <Grid
        flex={1}
        container
        spacing={2}
        marginLeft="10px"
        marginRight="10px"
        marginTop="10px"
        marginBottom="30px"
      >
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            User information
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            Name:{" "}
            {getCurrentUser()?.first_name + " " + getCurrentUser()?.last_name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Email: {getCurrentUser()?.email}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Phone: {getCurrentUser()?.phone}
          </Typography>
        </Grid>
        {isHolder() && (
          <>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                gutterBottom
                style={{ paddingRight: 20 }}
              >
                Custom notes (e.g. instructions to find your home, apartment
                buzz number, call first etc.):
              </Typography>
              {!isAboutNotesEditing ? (
                <div>
                  <Typography variant="body1" gutterBottom>
                    {locationNote}{" "}
                    <IconButton onClick={handleAboutNotesEditClick}>
                      <EditIcon />
                    </IconButton>
                  </Typography>
                </div>
              ) : (
                <div>
                  <TextField
                    label="About Notes"
                    multiline
                    rows={3}
                    size="small"
                    fullWidth
                    sx={{ maxWidth: "400px", padding: "4px" }}
                    variant="outlined"
                    value={locationNote}
                    onChange={(e) => setLocationNote(e.target.value)}
                  />
                  <IconButton onClick={handleAboutNotesSaveClick}>
                    <CheckIcon />
                  </IconButton>
                </div>
              )}
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              color: "#ffffff",
              fontWeight: "550",
              padding: 1,
              fontSize: isSmallScreen ? "10px" : "14px",
              mb: 5,
            }}
            onClick={handleOpenChangePassword}
          >
            Change Password
          </Button>
        </Grid>
        <ChangePasswordDialog
          open={changePasswordOpen}
          onClose={handleCloseChangePassword}
          onChangePassword={handleChangePassword}
        />
        {isHolder() && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Address information
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} style={{ paddingLeft: 5, paddingRight: 20 }}>
              {userAddresses && (
                <AddressList
                  addresses={userAddresses}
                  onAddressUpdate={(updatedAddressItem) => {
                    const newAddresses = [];
                    for (const address of userAddresses) {
                      if (address.id === updatedAddressItem.addressId) {
                        newAddresses.push({
                          ...address,
                          isDefault: updatedAddressItem.is_primary,
                        });
                        setOperationHours(address.operationHours || {});
                        setLocationNote(address.locationNote || "");
                        updateUserInStorage({
                          default_address_name: address.name,
                          default_address_id: address.id,
                        });
                      } else {
                        newAddresses.push({
                          ...address,
                          isDefault: false,
                        });
                      }
                    }

                    setUserAddresses(newAddresses);
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  color: "#ffffff",
                  fontWeight: "550",
                  padding: 1,
                  fontSize: isSmallScreen ? "10px" : "14px",
                  mb: 5,
                }}
                onClick={handleClickOpen}
              >
                Add an address
              </Button>
              {addAddressOpen && (
                <AddAddress
                  onAddressSubmit={handleAddAddress}
                  addAddressOpen={addAddressOpen}
                  setAddressOpen={setAddAddressOpen}
                />
              )}
            </Grid>
          </>
        )}
        {isHolder() && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Working Hours{" "}
                <IconButton onClick={handleWorkingHoursEditClick}>
                  <EditIcon />
                </IconButton>
              </Typography>
              <Divider />
            </Grid>
            {daysOfWeek.map((day) => {
              const capitalizedDay = day.charAt(0).toUpperCase() + day.slice(1);
              const dayInfo = operationHours.find(
                (item) => item.day.toLowerCase() === day.toLowerCase()
              );
              const formatTime = (time) =>
                new Date(0, 0, 0, time, 0).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                });
              return (
                <Grid item xs={12} key={day}>
                  <Typography style={{ lineHeight: "0.4" }}>
                    {capitalizedDay}:{" "}
                    {dayInfo && dayInfo.from !== null && dayInfo.to !== null
                      ? `${formatTime(dayInfo.from)} - ${formatTime(
                          dayInfo.to
                        )}`
                      : "Not Available"}
                  </Typography>
                </Grid>
              );
            })}
            <Grid item xs={12} sx={{ mt: 5 }}>
              <Typography variant="h6" gutterBottom>
                Payment Account{" "}
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  color: "#ffffff",
                  fontWeight: "550",
                  padding: 1,
                  fontSize: isSmallScreen ? "10px" : "14px",
                }}
                onClick={handleProcessPayment}
              >
                Manage Your Payout Account
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      {isHolder() && (
        <WorkingHours
          operationHours={operationHours}
          setOperationHours={setOperationHours}
          isWorkingHoursEditing={isWorkingHoursEditing}
          setIsWorkingHoursEditing={setIsWorkingHoursEditing}
        />
      )}
    </div>
  );
};

export default Account;
