import * as React from 'react';
import {
  FC,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';
import { getEmailConfirmSend } from 'service/requests/users';

import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import * as Controller from './controller';

interface ComponentProps {
  setIsSignin: (isSignin: boolean) => void;
}

const SigninFormContent: FC<ComponentProps> = ({ setIsSignin }) => {
  const { handleLogin } = Controller.useLogin();
  const { handleEmailConfirmation } = Controller.useConfirmation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVerificationDialogOpen, setVerificationDialogOpen] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    const data = new FormData(event.currentTarget);
    const signInData = {
      email: data.get("email")?.toString().trim() || "",
      password: data.get("password")?.toString().trim(),
    };
    if (!isForgotPassword) {
      handleLogin(signInData)
        .then((res) => {
          if (res === true) {
            setVerificationDialogOpen(true);
          } else if (
            res &&
            typeof res === "object" &&
            res.hasOwnProperty("url")
          ) {
            window.location.replace(res.url);
          } else {
            window.location.replace(
              `${window.location.origin}${process.env.PUBLIC_URL}`
            );
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
      setUserEmail(signInData.email);
    } else {
      setIsForgotPassword(false);
    }
  };

  const showSuccessDialog = () => {
    window.alert("Verification successful. Please sign in again.");
  };

  const handleVerificationConfirm = (event) => {
    console.log("handleVerificationConfirm", event);
    handleEmailConfirmation(
      {
        email: userEmail,
        email_confirmation_code: verificationCode,
      },

      () => {
        showSuccessDialog();
      }
    )
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setVerificationDialogOpen(false);
      });
  };

  const handleResendCode = () => {
    getEmailConfirmSend({ email: userEmail })
      .then(() => {
        console.log("Verification code resent successfully");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /*const handleForgotPassword = () => {
    setIsForgotPassword(true);
    navigate("/forgot-password");
  };*/

  return (
    <>
      <div style={{ textAlign: "left" }}>
        <Typography
          component="h1"
          variant="h5"
          sx={{
            marginBottom: 1,
            fontSize: "23px",
            "@media (max-width: 900px)": {
              fontSize: "19px",
              marginTop: 11,
            },
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            fontWeight: "500",
            fontFamily: "Rubik, sans-serif",
          }}
        >
          Welcome back!
        </Typography>
        <Typography
          fontSize={12}
          sx={{ marginBottom: 3, fontFamily: "Rubik, sans-serif" }}
        >
          Secure, community-based, package storage with Hold This
        </Typography>
      </div>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          sx={{ mt: 4 }}
        />
        {/*<FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
        />*/}

        <Dialog
          open={isVerificationDialogOpen}
          onClose={() => setVerificationDialogOpen(false)}
        >
          <DialogContent>
            <Typography variant="body1" style={{ marginBottom: "16px" }}>
              We've sent a verification code to your email. If you don’t see it
              after a couple minutes, please check your Spam folder or ‘RESEND
              CODE’.
            </Typography>
            <DialogTitle>Enter Verification Code</DialogTitle>
            <TextField
              label="Verification Code"
              variant="outlined"
              fullWidth
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <DialogActions>
              <Button onClick={handleVerificationConfirm}>Confirm</Button>
              <Button onClick={handleResendCode}>Resend code</Button>
            </DialogActions>
          </DialogActions>
        </Dialog>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <LoadingButton
            data-cy="submit"
            fullWidth
            type="submit"
            loading={isSubmitting}
            sx={{
              mt: 3,
              mb: 2,
              padding: "8px 5px",
              fontSize: "0.75rem",
              fontWeight: "600",
              width: "30%",
              backgroundColor: "#ff741e",
              borderRadius: "20px",
              boxShadow: "0px 4px 4px #00000040",
              fontFamily: "Rubik, sans-serif",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#ffac1e",
              },
              textAlign: "center",
            }}
          >
            LOGIN
          </LoadingButton>
        </div>
        <Grid container>
          {/*<Grid item xs>
            <Link
              onClick={handleForgotPassword}
              style={{
                color: "#ff741e",
                fontFamily: "Rubik, sans-serif",
                cursor: "pointer",
              }}
            >
              Forgot password?
            </Link>
            </Grid>*/}
          <Grid
            item
            sx={{
              marginBottom: 1.5,
            }}
          >
            <Link
              href="#i"
              onClick={() => setIsSignin(false)}
              variant="body2"
              style={{ textDecoration: "none" }}
            >
              <span
                style={{ color: "#474747", fontFamily: "Rubik, sans-serif" }}
              >
                Don't have an account?
              </span>{" "}
              <span
                style={{ color: "#ff741e", fontFamily: "Rubik, sans-serif" }}
              >
                Sign Up
              </span>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default React.memo(SigninFormContent);
