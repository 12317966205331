import React from "react";
import SbChannelList from "@sendbird/uikit-react/GroupChannelList";

import { useNavigate, useParams } from "react-router-dom";
import SbChannel from "@sendbird/uikit-react/GroupChannel";

export function MobileChannelList() {
  const { channelUrl } = useParams();
  // const [searchParams] = useSearchParams();
  // const messageId = searchParams.get("messageId");
  // const createdAt = searchParams.get("createdAt");

  const navigate = useNavigate();

  return (
    <div style={{ height: "90vh" }}>
      {!channelUrl ? (
        <SbChannelList
          selectedChannelUrl={channelUrl}
          allowProfileEdit={false}
          disableAutoSelect
          onChannelCreated={(channel) => {}}
          onChannelSelect={(channel) => {
            if (channel) {
              navigate(`/messages/${channel.url}`);
            }
          }}
        />
      ) : (
        <SbChannel
          channelUrl={channelUrl as string}
          onBackClick={() => {
            navigate("/messages");
          }}
        />
      )}
    </div>
  );
}
