import { dataProvider } from 'api';
import {
  IChatAccessToken,
  IEmailConfirm,
  ITokens,
  IUser,
  IUserPatch,
  UserRole,
} from 'interfaces';
import {
  change_password_path,
  chat_access_token,
  connect_account,
  email_confirm_send_path,
  email_confirm_verify_path,
  forgot_password_path,
  login_path,
  signup_path,
  user_estimate,
  user_me_address,
  user_me_path,
} from 'service';
import { isHolder } from 'utils';

export const postSignin = async (params): Promise<ITokens> =>  {
   console.log('postSignin params:', params);
    return await dataProvider
    .post(login_path(), {
      json: params,
    })
      .json();
}; 

export const postSignUp = async (params): Promise<any> =>  {
  console.log('postSignUp params:', params);
   return await dataProvider
    .post(signup_path(), {
      json: params,
    })
    .json().then((data) => {
      return params;
    });
};

export const postEmailConfirm = async ({
  email, email_confirmation_code,
}: IEmailConfirm): Promise<null> => {
  // Confirm verification email by giving authKey and email by PUT method against users/verify
  await dataProvider
    .put(email_confirm_verify_path(), {
      json: {
        "email": email,
        "authKey": email_confirmation_code,
      },
    })
    .json();
}

export const getEmailConfirmSend = async (params): Promise<Response> => 
  // Sent verification email to user using Get method users/verify/foo@gmail.com
  await dataProvider.get(email_confirm_send_path() + params.email).json();

export const getUserMe = async (): Promise<IUser> =>
  dataProvider.get(user_me_path()).json();

export const putUserMe = async ({
  locationNote,
  operationHours,
  addressId,
}: IUserPatch): Promise<Response> => {
  
  const address = { };
  if (operationHours) {
    address.operationHours = operationHours;
  }
  if (locationNote) {  
    address.locationNote = locationNote;
  }
  return dataProvider.put(user_me_address(addressId), {
    json: { address },
  });
}

interface Connect {
  url: string;
}

export const connectAccount = async (): Promise<Connect> => { 
  try {
    return await dataProvider.post(connect_account(), {}).json();
  } catch (error) {
    throw error;    
  }
}

export const verfiyOnReturn = async (code: string|null, accountId: string|null): Promise<any> => {
  // const currentUserRole = getCurrentUser().role;
  const currentUserRole = isHolder() ? UserRole.HOLDER : UserRole.CUSTOMER;
  const headers = {
    'HT-role': currentUserRole,
  };

  const queryParams = new URLSearchParams({
    code: code ? code : '',
    connectId: accountId ? accountId : '',
  });
  
  const url = `${connect_account()}?${queryParams}`;

  return dataProvider.get(url, { headers }).json();
};

export const userEstimate = async (addressId: string): Promise<any> => {
  return dataProvider.get(user_estimate(addressId)).json();
}

export const getChatAccessToken = async (): Promise<IChatAccessToken> => {
  // const currentUserRole = getCurrentUser().role;
  const currentUserRole = isHolder() ? UserRole.HOLDER : UserRole.CUSTOMER;
  const headers = {
    'HT-role': currentUserRole,
  };
  const url = `${chat_access_token()}`;
   return dataProvider.get(url, { headers }).json();
}

export const forgotPassword = async (params): Promise<any> => {
  console.log('laura', 'forgotPassword')
  return dataProvider.put(forgot_password_path(), params).json();
};

export const changePassword = async ({
  password,
  oldPassword,
}: IUserPatch): Promise<Response> => { 
  
  const user = { };
  if (password) {
    user.password = password;
  }
  if (oldPassword) {
    user.oldPassword = oldPassword;
  }
  return dataProvider.put(change_password_path(), {
    json: { user },
  });
}
