import React, { useState } from 'react'
import SbChannel from '@sendbird/uikit-react/GroupChannel'
import SbChannelList from '@sendbird/uikit-react/GroupChannelList'

export const DesktopChannelList = () => {
  const [currentChannelUrl, setCurrentChannelUrl] = useState('');

  return (
    <div className='sendbird-chat-desktop'>
      <div className='sendbird-chat-desktop__channel-list'>
        <SbChannelList
          selectedChannelUrl={currentChannelUrl}
          allowProfileEdit={false}
          disableAutoSelect
          onChannelCreated={(channel) => {}}
          onChannelSelect={(channel) => {
            if (channel) {
              setCurrentChannelUrl(channel?.url);
            }
          }}
        />
      </div>
      <SbChannel channelUrl={currentChannelUrl} />
    </div>
  )
}