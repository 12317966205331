import React, { useEffect, useState } from "react";
import SBProvider from "@sendbird/uikit-react/SendbirdProvider";
import { getCurrentUser, isHolder, isLoggedIn } from "utils";
import { UserRole } from "interfaces";
import { getChatAccessToken } from "service";
import LoadingIndicator from "components/LoadingIndicator";
import { ChatColorSet } from "components/Message";
import { DesktopChannelList } from "components/Message";
import { MobileChannelList } from "components/Message";
import "../components/Message/Messages.css";

const Messages: React.FC = () => {
  if (!isLoggedIn()) {
    window.location.href = `${process.env.PUBLIC_URL}/login`;
  }

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const APP_ID = "222D2AFC-D7A0-4F26-9333-F677291078F4";
  const userData = getCurrentUser();
  const role = isHolder() ? UserRole.HOLDER : UserRole.CUSTOMER;
  const chatUserId = `${role}${userData.id}`;
  const [userId] = useState<string | null>(chatUserId);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isConnecting, setIsConnecting] = React.useState(true);

  useEffect(() => {
    const fetchConversationId = async () => {
      try {
        const result = await getChatAccessToken();
        setAccessToken(result.accessToken ? result.accessToken : null);
        setIsConnecting(false);
      } catch (error) {
        console.error("Error fetching conversation ID:", error);
        setIsConnecting(false);
      }
    };

    fetchConversationId();
  }, []);

  return (
    <>
      {isConnecting && <LoadingIndicator />}
      {!isConnecting && (
        <div className="sendbird-app">
          <SBProvider
            appId={APP_ID}
            userId={userId!}
            accessToken={accessToken!}
            allowProfileEdit={false}
            theme={"light"}
            colorSet={ChatColorSet}
            breakpoint={isMobile}
          >
            {isMobile ? <MobileChannelList /> : <DesktopChannelList />}
          </SBProvider>
        </div>
      )}
    </>
  );
};
export default Messages;
